import React from "react"
import ReactRough, { Rectangle } from "react-rough"
import Div100vh from "react-div-100vh"
import { SizeMe } from "react-sizeme"
import SEO from "../components/SEO"
import MikeyAriText from "../components/MikeyAriText"
import MukeAriImage from "../components/MikeyAriImage"
import SaveTheDateGeo from "../components/SaveTheDateGeo"
import "../styles/save-the-date.css"

const SaveTheDate = () => (
  <main className="save-the-date">
    <SEO title="Wills you be mine? 🤵👰" />
    <Div100vh
      style={{
        position: "relative",
        height: "100rvh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <SizeMe monitorHeight={true}>
        {({ size }) => (
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
          >
            <div className="canvas">
              <ReactRough width={size.width} height={size.height}>
                <Rectangle
                  points={[40, 80, size.width - 80, size.height - 160]}
                  stroke="white"
                  roughness={1.5}
                  bowing={0.5}
                  strokeWidth={3}
                />
              </ReactRough>
            </div>
          </div>
        )}
      </SizeMe>
      <div className="muke-ari-visual">
        <MikeyAriText className="mikey-ari-text-wrapper" />
        <div style={{ width: "100%", maxHeight: "100%" }}>
          <MukeAriImage className="mikey-ari-image" />
        </div>
      </div>
      <div className="save-the-date-copy">
        <SaveTheDateGeo />
        <h1 className="save-the-date-title">Save Our Date</h1>
        <p className="save-the-date-details">Saturday, May 16th 2020</p>
        <p className="save-the-date-details line-break">Palm Springs, CA</p>
        <p className="save-the-date-details line-break">
          More Information To Follow
        </p>
        <div className="save-the-date-meta">
          <p>Say no more, ready to stay with us?</p>
          <p>
            <a href="https://gc.synxis.com/rez.aspx?Hotel=1921&Chain=5154&arrive=5/14/2020&depart=5/18/2020&adult=2&child=0&group=200516KAJD">
              Book Now
            </a>
          </p>
        </div>
      </div>
    </Div100vh>
  </main>
)

export default SaveTheDate
