import React from "react"

const SaveTheDateGeo = () => {
  return (
    <svg
      width="360"
      height="307"
      viewBox="0 0 360 307"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="save-the-date-geo"
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="30.892562%"
          x2="100%"
          y2="69.107438%"
          id="yellowGradient"
        >
          <stop stopColor="#FDE220" offset="0%"></stop>
          <stop stopColor="#FAC10E" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="greyGradient">
          <stop stopColor="#CAD6D2" offset="0%"></stop>
          <stop stopColor="#DEE9E6" offset="100%"></stop>
        </linearGradient>
      </defs>
      <rect
        x="84.5078"
        y="10"
        width="356.297"
        height="326.513"
        transform="rotate(15 84.5078 10)"
        fill="url(#greyGradient)"
      />
      <rect
        x="100.508"
        width="356.297"
        height="326.513"
        transform="rotate(15 100.508 0)"
        fill="url(#yellowGradient)"
      />
    </svg>
  )
}

export default SaveTheDateGeo
